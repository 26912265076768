import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollections"
const db = firebase.firestore();
import Vue from 'vue'


export default {
    namespaced: true,
    state: {
      userData:{}
    },
    getters: {
      getUserData: state => state.userData
    },
    mutations: {
      setUserDataArr(state,payload){
        state.userData = payload;
      },
      updateUser(state,payload){
        state.userData = payload;
      }
    },
    actions: {
      getUserCollection(state){
        return new Promise((resolve,reject)=>{
          try{
            var userData = {};
            db.collection(dbCollections.SHOPIFYUSER).doc(Vue.prototype.$userId).onSnapshot((snapshot)=>{
                if(snapshot.empty){
                    state.commit("setUserDataArr",userData);
                    resolve("No Data Found.");
                }
                else
                {
                    userData = snapshot.data();
                    state.commit("setUserDataArr",userData);
                    resolve("Data Found.");              
                }
              })
          }
          catch(error){
            console.error("error",error);
            reject(error);
          }
        });
      }
    },
}
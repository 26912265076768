<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <!-- <b-spinner
      v-if="isSpinner"
      class="spinner"
    /> -->
    <div v-if="isValid && !isSpinner"><HeaderComponent /></div>
    <div class="main-content">
      <router-view />
    </div>
    <div v-if="isValid && !isSpinner">
      <FooterComponent/>
    </div>
    <div v-else-if="!isSpinner">
      INVALID URL
    </div>
  </div>
</template>



<script>
import HeaderComponent from "./components/layouts/headerComponent.vue";
import FooterComponent from "./components/layouts/footerComponent.vue";
import { mapActions } from "vuex";
// import Vue from 'vue'
// import {dbCollections} from '@/utils/firebaseCollections';
// import firebase from '@/utils/firebaseInit';
// const db = firebase.firestore()
export default {
  components: {
    HeaderComponent,
    FooterComponent,
  },
  data() {
    return {
      isValid: false,     // true if you want to use without shopify
      isSpinner: true,  // false if you want to use without shopify
      isHeaderShow: true, // false if you want to
    }
  },
  created() {
    try {     
      this.$root.$on("validIframe", (spinner,valid)=> {
        this.isSpinner = spinner;
        this.isValid = valid;
      });
      this.getSett();
      // Vue.prototype.$userId = this.$route.query.id;
      // console.log(this.$route.query.id,"this.$route.query.id");
      // setTimeout(function () {
      //   var h_height = document.getElementsByTagName("header")[0]?.offsetHeight;
      //   var f_height = document.getElementsByTagName("footer")[0]?.offsetHeight;
      //   var w_height = window.outerHeight;
      //   var t_height = w_height - (h_height + f_height);
      //   if (document.getElementsByClassName("main-content") && document.getElementsByClassName("main-content")[0]) {
      //     document.getElementsByClassName("main-content")[0].style.minHeight =
      //       t_height + "px";
      //   }
      //   }, 1000);
      // var onresize = function () {
      //   var h_height = document.getElementsByTagName("header")[0]?.offsetHeight;
      //   var f_height = document.getElementsByTagName("footer")[0]?.offsetHeight;
      //   var w_height = window.outerHeight;
      //   var t_height = w_height - (h_height + f_height);
      //   if (document.getElementsByClassName("main-content") &&  document.getElementsByClassName("main-content")[0]) {
      //     document.getElementsByClassName("main-content")[0].style.minHeight =
      //       t_height + "px";
      //   }
      // };
      // /* eslint-disable */
      // window.addEventListener("resize", onresize);
      // // Coment If Use For Without Shopify
      // window.addEventListener("message", (e) => {
      //   // try {
      //   //   // this.isValid = true;
      //   //   // this.isSpinner = false;
      //   //   if (e.srcElement && e.srcElement.location && e.srcElement.location.ancestorOrigins[0] == 'https://nodedemo18.myshopify.com') {
      //   //     // this.isSpinner = true;
      //   //     // this.isShow = true;
      //   //       if (Vue.prototype.$userId === undefined || Vue.prototype.$userId === '') {
      //   //           this.isValid = false;
      //   //           this.isSpinner = false;
      //   //           this.$root.$emit('show',false);
      //   //       } else {
      //   //         db.collection(dbCollections.SHOPIFYUSER).where('id', '==', Number(Vue.prototype.$userId)).get().then((resp)=>{
      //   //           if (!resp.empty) {
      //   //             this.isValid = true;
      //   //             this.isSpinner = false;
      //   //             this.$root.$emit('show',true);
      //   //           } else {
      //   //             this.isValid = false;
      //   //             this.isSpinner = false;
      //   //           }
      //   //         }).catch((error)=>{
      //   //           console.error(error);
      //   //           this.isValid = false;
      //   //           this.isSpinner = false;
      //   //         })
      //   //     }
      //   //   } else {
      //   //     // this.isValid = false;
      //   //     this.isSpinner = false;
      //   //   }
      //   // } catch (error) {
      //   //   this.isSpinner = false;
      //   //   console.error(error);
      //   // }
      // });
      // this.getSett();
    } catch (error) {
      console.error(error);
      this.isSpinner = false;
    }
  },
  methods: {
    ...mapActions({
      getSett: 'setting/getSettingsData'
    })
  },
};
</script>
<style>
@font-face {
  font-family: "Chivo-Regular";
  src: url("./assets/fonts/Chivo-Regular.eot");
  src: url("./assets/fonts/Chivo-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Chivo-Regular.woff2") format("woff2"),
    url("./assets/fonts/Chivo-Regular.woff") format("woff"),
    url("./assets/fonts/Chivo-Regular.ttf") format("truetype"),
    url("./assets/fonts/Chivo-Regular.svg#Chivo-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Chivo-Black";
  src: url("./assets/fonts/Chivo-Black.eot");
  src: url("./assets/fonts/Chivo-Black.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Chivo-Black.woff2") format("woff2"),
    url("./assets/fonts/Chivo-Black.woff") format("woff"),
    url("./assets/fonts/Chivo-Black.ttf") format("truetype"),
    url("./assets/fonts/Chivo-Black.svg#Chivo-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Chivo-ExtraBold";
  src: url("./assets/fonts/Chivo-ExtraBold.eot");
  src: url("./assets/fonts/Chivo-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Chivo-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Chivo-ExtraBold.woff") format("woff"),
    url("./assets/fonts/Chivo-ExtraBold.ttf") format("truetype"),
    url("./assets/fonts/Chivo-ExtraBold.svg#Chivo-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Chivo-Bold";
  src: url("./assets/fonts/Chivo-Bold.eot");
  src: url("./assets/fonts/Chivo-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Chivo-Bold.woff2") format("woff2"),
    url("./assets/fonts/Chivo-Bold.woff") format("woff"),
    url("./assets/fonts/Chivo-Bold.ttf") format("truetype"),
    url("./assets/fonts/Chivo-Bold.svg#Chivo-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MyriadPro-Regular";
  src: url("./assets/fonts/MyriadPro-Regular.eot");
  src: url("./assets/fonts/MyriadPro-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/MyriadPro-Regular.woff2") format("woff2"),
    url("./assets/fonts/MyriadPro-Regular.woff") format("woff"),
    url("./assets/fonts/MyriadPro-Regular.ttf") format("truetype"),
    url("./assets/fonts/MyriadPro-Regular.svg#MyriadPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ArialMT";
  src: url("./assets/fonts/ArialMT.eot");
  src: url("./assets/fonts/ArialMT.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/ArialMT.woff2") format("woff2"),
    url("./assets/fonts/ArialMT.woff") format("woff"),
    url("./assets/fonts/ArialMT.ttf") format("truetype"),
    url("./assets/fonts/ArialMT.svg#ArialMT") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Arial-Lgt";
  src: url("./assets/fonts/Arial-Lgt.eot");
  src: url("./assets/fonts/Arial-Lgt.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Arial-Lgt.woff2") format("woff2"),
    url("./assets/fonts/Arial-Lgt.woff") format("woff"),
    url("./assets/fonts/Arial-Lgt.ttf") format("truetype"),
    url("./assets/fonts/Arial-Lgt.svg#Arial-Lgt") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Arial";
  src: url("./assets/fonts/Arial.eot");
  src: url("./assets/fonts/Arial.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Arial.woff2") format("woff2"),
    url("./assets/fonts/Arial.woff") format("woff"),
    url("./assets/fonts/Arial.ttf") format("truetype"),
    url("./assets/fonts/Arial.svg#Arial") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Chivo-Medium";
  src: url("./assets/fonts/Chivo-Medium.eot");
  src: url("./assets/fonts/Chivo-Medium.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Chivo-Medium.woff2") format("woff2"),
    url("./assets/fonts/Chivo-Medium.woff") format("woff"),
    url("./assets/fonts/Chivo-Medium.ttf") format("truetype"),
    url("./assets/fonts/Chivo-Medium.svg#Chivo-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Chivo-Light";
  src: url("./assets/fonts/Chivo-Light.eot");
  src: url("./assets/fonts/Chivo-Light.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Chivo-Light.woff2") format("woff2"),
    url("./assets/fonts/Chivo-Light.woff") format("woff"),
    url("./assets/fonts/Chivo-Light.ttf") format("truetype"),
    url("./assets/fonts/Chivo-Light.svg#Chivo-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Chivo-Bold";
  src: url("./assets/fonts/Chivo-Bold.eot");
  src: url("./assets/fonts/Chivo-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Chivo-Bold.woff2") format("woff2"),
    url("./assets/fonts/Chivo-Bold.woff") format("woff"),
    url("./assets/fonts/Chivo-Bold.ttf") format("truetype"),
    url("./assets/fonts/Chivo-Bold.svg#Chivo-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
</style>


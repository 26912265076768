<template>
    <div class="master-dashboard-section production-section">
        <!-- <div class="heading-text">
        <h3>Master DashBoard</h3>
        <p>You can view, schedule, or edit requests below:</p>
        </div> -->
        <div class="all-box-wrapper">
        <div class="form-wrapper">
            <b-form>
                <!-- <button @click="getDatassss">GET</button> -->
            <div class="serach-request">
                <b-form-group>
                <b-form-input
                    class="serachResult"
                    v-model="form.serachRequest"
                    type="text"
                    placeholder="Search Requests"
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="all-select-wrapper">
                <b-form-group label="Type:" label-for="type" class="typeSelect">
                <b-form-select
                    id="type"
                    v-model="form.type"
                    :options="typeOptions"
                    class="form-control dropdownimages"
                ></b-form-select>
                </b-form-group>
                <b-form-group
                label="Status"
                label-for="status"
                class="statusSelect"
                style="margin-left: 10px;"
                >
                <b-form-select
                    id="status"
                    v-model="form.status"
                    :options="statusOptions"
                    class="form-control dropdownimages"
                ></b-form-select>
                </b-form-group>
            </div>
            </b-form>
        </div>
        <div class="all-select-wrapper new-request-hide-detail">
            <div class="clientSelect"></div>
            <div
            class="statusSelect hide-detail-div"
            v-if="newrequest || production || postproduction || completed || canceled || draft"
            @click="HideDeatil()"
            >
            Hide details
            </div>
            <div
            class="statusSelect hide-detail-div"
            v-if="!newrequest && !production && !postproduction && !completed && !canceled && !draft"
            @click="ShowDeatil()"
            >
            Show details
            </div>
            <div class="typeSelect"></div>
        </div>
        <!-- New Request Table start -->
        <div class="all-tables-wrapper" v-if="form.status == 1 || form.status == null">
            <div class="common-table-hading">
            <div class="common-table-title">
                <label
                >New Request(s)
                <span class="v-title"
                    ><img
                    class="quetionImage"
                    style="
                        width: 15px;
                        height: 15px;
                        object-fit: cover;
                        vertical-align: baseline;
                    "
                    src="../assets/questionn.png"
                    />
                    <span @click="newrequestToggle" style="cursor: pointer">
                    <img
                        class="arrow-toggle-image"
                        src="../assets/v.png"
                        style="width: 12px; padding-left: 4px; cursor: pointer"
                        :class="{ clicked: newrequest }"
                    />
                    </span>
                </span>
                </label>
                <span>{{itemsNewRequest.length}} Record(s)	/ {{totalCreditNewRequest}}	Credit(s)</span>
            </div>
            <div v-if="newrequest">
                <div class="newrequest-table-data" v-if="itemsNewRequest.length">
                    <b-table :fields="fieldsNewRequest" :items="pagedRowsNewRequest" responsive="sm" id="newRequest">
                    <template #cell(projectname)="data">
                        <div class="project-name" style="color: #40a6d5">
                        <!-- <router-link to="/video-request">{{
                            data.item.projectname
                        }}</router-link> -->
                        <router-link :to="`request-detail/${data.item.id}/?name=Dashboard&projectName=${data.item.projectname}`">{{ data.item.projectname}}</router-link>
                        </div>
                    </template>
                    <template #cell(Type)="data">
                        <div>{{ data.item.Type }}</div>
                    </template>
                    <template #cell(Credits)="data">
                        <div style="text-align center">{{ data.item.Credits }}</div>
                    </template>
                    <template #cell(videotype)="data">
                        <div>{{ data.item.videotype }}</div>
                    </template>
                    <template #cell(Submitted)="data">
                        <div>{{ data.item.Submitted }}</div>
                    </template>
                    <template #cell(shootdate)="data">
                        <div>{{ data.item.shootdate }}</div>
                    </template>
                    <template #cell(v1Target)="data">
                        <div>{{ data.item.v1Target }}</div>
                    </template>
                    <template #cell(addons)="data">
                        <div>{{ data.item.addons }}</div>
                    </template>
                    </b-table>
                    <div>
                        <b-pagination
                        class="pagination"
                        v-model="currentPageNewRequest"
                        :total-rows="itemsNewRequest.length"
                        :per-page="perPageNewRequest"
                        aria-controls="my-table"
                        ></b-pagination>
                    </div>
                </div>
                <div v-else class="newrequest-table-data new-request-detaildata" style="text-align: center;">
                    No Records Found
                </div>
            </div>
            </div>
        </div>

        <div class="all-tables-wrapper" v-if="form.status == 6 || form.status == null">
            <div class="common-table-hading">
            <div class="common-table-title">
                <label
                >Draft
                <span class="v-title"
                    ><img
                    class="quetionImage"
                    style="
                        width: 15px;
                        height: 15px;
                        object-fit: cover;
                        vertical-align: baseline;
                    "
                    src="../assets/questionn.png"
                    />
                    <span @click="draftToggle" style="cursor: pointer">
                    <img
                        class="arrow-toggle-image"
                        src="../assets/v.png"
                        style="width: 12px; padding-left: 4px; cursor: pointer"
                        :class="{ clicked: draft }"
                    />
                    </span>
                </span>
                </label>
                <span>{{itemsDraft.length}} Record(s)</span>
            </div>
            <div v-if="draft">
                <div class="newrequest-table-data" v-if="itemsDraft.length">
                    <b-table :fields="fieldsDraft" :items="pagedRowsDraft" responsive="sm" id="draft">
                    <template #cell(projectname)="data">
                        <div>
                        <!-- <router-link to="/video-request">{{
                            data.item.projectname
                        }}</router-link> -->
                            {{ data.item.projectname}}
                        </div>
                    </template>
                    <template #cell(Type)="data">
                        <div>{{ data.item.Type }}</div>
                    </template>
                    <template #cell(Credits)="data">
                        <div style="text-align center">{{ data.item.Credits }}</div>
                    </template>
                    <template #cell(videotype)="data">
                        <div>{{ data.item.videotype }}</div>
                    </template>
                    <template #cell(Submitted)="data">
                        <div>{{ data.item.Submitted }}</div>
                    </template>
                    <template #cell(shootdate)="data">
                        <div>{{ data.item.shootdate }}</div>
                    </template>
                    <template #cell(v1Target)="data">
                        <div>{{ data.item.v1Target }}</div>
                    </template>
                    <template #cell(addons)="data">
                        <div>{{ data.item.addons }}</div>
                    </template>
                    <template #cell(action)="data">
                        <div>
                            <div class="complete-form" @click="redirectToForm(data.item)">Complete</div>
                        </div>
                    </template>
                    <template #cell(delete)="data">
                        <div>
                            <div class="complete-form" @click="deleteForm(data.item)">Delete</div>
                        </div>
                    </template>
                    </b-table>
                    <div>
                        <b-pagination
                        class="pagination"
                        v-model="currentPageDraft"
                        :total-rows="itemsDraft.length"
                        :per-page="perPageDraft"
                        aria-controls="my-table"
                        ></b-pagination>
                    </div>
                </div>
                <div v-else class="newrequest-table-data new-request-detaildata" style="text-align: center;">
                    No Records Found
                </div>
            </div>
            </div>
        </div>
        <!-- New Request Table start -->
        <!-- Production Table start -->
        <div class="all-tables-wrapper production-table-wrapper" v-if="form.status == 2 || form.status == null">
            <div class="common-table-hading">
            <div class="common-table-title">
                <label
                >Production
                <span class="v-title"
                    ><img
                    class="quetionImage"
                    style="
                        width: 15px;
                        height: 15px;
                        object-fit: cover;
                        vertical-align: baseline;
                    "
                    src="../assets/questionn.png"
                    />
                    <span @click="productionToggle" style="cursor: pointer">
                    <img
                        class="arrow-toggle-image"
                        src="../assets/v.png"
                        style="width: 12px; padding-left: 4px; cursor: pointer"
                        :class="{ clicked: production }"
                    />
                    </span> </span
                ></label>
                <span>{{itemsProduction.length}} Record(s)	/ {{totalCreditProduction}}	Credit(s)</span>
            </div>
            <div v-if="production">
                <div class="newrequest-table-data" v-if="itemsProduction.length">
                    <b-table
                    :fields="fieldsProduction"
                    :items="pagedRowsProduction"
                    responsive="sm"
                    id="production"
                    >
                    <template #cell(projectname)="data">
                        <div class="project-name" style="color: #40a6d5">
                        <!-- <router-link to="/video-request">{{
                            data.item.projectname
                        }}</router-link> -->
                        <router-link :to="`request-detail/${data.item.id}/?name=Dashboard&projectName=${data.item.projectname}`">{{ data.item.projectname}}</router-link>
                        </div>
                    </template>
                    <template #cell(Type)="data">
                        <div>{{ data.item.Type }}</div>
                    </template>
                    <template #cell(Credits)="data">
                        <div style="text-align center">{{ data.item.Credits }}</div>
                    </template>
                    <template #cell(videotype)="data">
                        <div>{{ data.item.videotype }}</div>
                    </template>
                    <template #cell(Submitted)="data">
                        <div>{{ data.item.Submitted }}</div>
                    </template>
                    <template #cell(shootdate)="data">
                        <div>{{ data.item.shootdate }}</div>
                    </template>
                    <template #cell(v1Target)="data">
                        <div>{{ data.item.v1Target }}</div>
                    </template>
                    <template #cell(addons)="data">
                        <div>{{ data.item.addons }}</div>
                    </template>
                    </b-table>
                    <div>
                        <b-pagination
                        class="pagination"
                        v-model="currentPageProduction"
                        :total-rows="itemsProduction.length"
                        :per-page="perPageProduction"
                        aria-controls="my-table"
                        ></b-pagination>
                    </div>
                </div>
                <div v-else class="newrequest-table-data new-request-detaildata" style="text-align: center;">
                    No Records Found
                </div>
            </div>
            </div>
        </div>
        <!-- Production Table end -->
        <!-- Post Production Table start -->
        <div class="all-tables-wrapper post-production-wrapper" v-if="form.status == 3 || form.status == null">
            <div class="common-table-hading">
            <div class="common-table-title">
                <label
                >Post Production
                <span class="v-title"
                    ><img
                    class="quetionImage"
                    style="
                        width: 15px;
                        height: 15px;
                        object-fit: cover;
                        vertical-align: baseline;
                    "
                    src="../assets/questionn.png"
                    />
                    <span @click="postproductionToggle" style="cursor: pointer">
                    <img
                        class="arrow-toggle-image"
                        src="../assets/v.png"
                        style="width: 12px; padding-left: 4px; cursor: pointer"
                        :class="{ clicked: postproduction }"
                    />
                    </span> </span
                ></label>
                <span>{{itemsPostProduction.length}} Record(s)	/ {{totalCreditPostProduction}}	Credit(s)</span>
            </div>
            <div v-if="postproduction">
                <div class="newrequest-table-data" v-if="itemsPostProduction.length">
                    <b-table
                    :fields="fieldsPostProduction"
                    :items="pagedRowsPostProduction"
                    responsive="sm"
                    id="postProduction"
                    >
                    <template #cell(projectname)="data">
                        <div class="project-name" style="color: #40a6d5">
                        <!-- <router-link to="/video-request">{{
                            data.item.projectname
                        }}</router-link> -->
                        <router-link :to="`request-detail/${data.item.id}/?name=Dashboard&projectName=${data.item.projectname}`">{{ data.item.projectname}}</router-link>
                        </div>
                    </template>
                    <template #cell(Type)="data">
                        <div>{{ data.item.Type }}</div>
                    </template>
                    <template #cell(Credits)="data">
                        <div style="text-align center">{{ data.item.Credits }}</div>
                    </template>
                    <template #cell(videotype)="data">
                        <div>{{ data.item.videotype }}</div>
                    </template>
                    <template #cell(Submitted)="data">
                        <div>{{ data.item.Submitted }}</div>
                    </template>
                    <template #cell(shootdate)="data">
                        <div>{{ data.item.shootdate }}</div>
                    </template>
                    <template #cell(v1Target)="data">
                        <div>{{ data.item.v1Target }}</div>
                    </template>
                    <template #cell(addons)="data">
                        <div>{{ data.item.addons }}</div>
                    </template>
                    </b-table>
                    <div>
                        <b-pagination
                        class="pagination"
                        v-model="currentPagePostProduction"
                        :total-rows="itemsPostProduction.length"
                        :per-page="perPagePostProduction"
                        aria-controls="my-table"
                        ></b-pagination>
                    </div>
                </div>
                <div v-else class="newrequest-table-data new-request-detaildata" style="text-align: center;">
                    No Records Found
                </div>
            </div>
            </div>
        </div>
        <!-- Post Production Table end -->
        <!-- Completed table css Start -->
        <div class="all-tables-wrapper completed-wrapper" v-if="form.status == 4 || form.status == null">
            <div class="common-table-hading">
            <div class="common-table-title">
                <label
                >Completed <span>(YTD)</span>
                <span class="v-title"
                    ><img
                    class="quetionImage"
                    style="
                        width: 15px;
                        height: 15px;
                        object-fit: cover;
                        vertical-align: baseline;
                    "
                    src="../assets/questionn.png"
                    />
                    <span @click="completedToggle" style="cursor: pointer">
                    <img
                        class="arrow-toggle-image"
                        src="../assets/v.png"
                        style="width: 12px; padding-left: 4px; cursor: pointer"
                        :class="{ clicked: completed }"
                    />
                    </span> </span
                ></label>
                <span>{{itemsCompleted.length}} Record(s)	/ {{totalCreditCompleted}} Credit(s)</span>
            </div>
            <div v-if="completed">
                <div class="newrequest-table-data" v-if="itemsCompleted.length">
                    <b-table
                    :fields="fieldsCompleted"
                    :items="pagedRowsCompleted"
                    responsive="sm"
                    id="completed"
                    >
                    <template #cell(projectname)="data">
                        <div class="project-name completed">
                        {{ data.item.projectname }}
                        </div>
                    </template>
                    <template #cell(Type)="data">
                        <div>{{ data.item.Type }}</div>
                    </template>
                    <template #cell(Credits)="data">
                        <div style="text-align center">{{ data.item.Credits }}</div>
                    </template>
                    <template #cell(videotype)="data">
                        <div>{{ data.item.videotype }}</div>
                    </template>
                    <template #cell(Submitted)="data">
                        <div>{{ data.item.Submitted }}</div>
                    </template>
                    <template #cell(shootdate)="data">
                        <div>{{ data.item.shootdate }}</div>
                    </template>
                    <template #cell(Completed)="data">
                        <div>{{ data.item.Completed }}</div>
                    </template>
                    <template #cell(addons)="data">
                        <div>{{ data.item.addons }}</div>
                    </template>
                    </b-table>
                    <div>
                        <b-pagination
                        class="pagination"
                        v-model="currentPageCompleted"
                        :total-rows="itemsCompleted.length"
                        :per-page="perPageCompleted"
                        aria-controls="my-table"
                        ></b-pagination>
                    </div>
                </div>
                    <div v-else class="newrequest-table-data new-request-detaildata" style="text-align: center;">
                    No Records Found
                </div>
            </div>
            </div>
        </div>
        <div class="all-tables-wrapper post-production-wrapper" v-if="form.status == 5 || form.status == null">
            <div class="common-table-hading">
            <div class="common-table-title">
                <label
                >Cancelled
                <span class="v-title"
                    ><img
                    class="quetionImage"
                    style="
                        width: 15px;
                        height: 15px;
                        object-fit: cover;
                        vertical-align: baseline;
                    "
                    src="../assets/questionn.png"
                    />
                    <span @click="canceledToggle" style="cursor: pointer">
                    <img
                        class="arrow-toggle-image"
                        src="../assets/v.png"
                        style="width: 12px; padding-left: 4px; cursor: pointer"
                        :class="{ clicked: canceled }"
                    />
                    </span> </span
                ></label>
                <span>{{itemsCancelled.length}} Record(s)	/ {{totalCreditCancelled}}	Credit(s)</span>
            </div>
            <div v-if="canceled">
                <div class="newrequest-table-data" v-if="canceled && itemsCancelled.length">
                    <b-table
                    :fields="fieldsCancel"
                    :items="pagedRowsCancelled"
                    responsive="sm"
                    id="cancel"
                    >
                    <template #cell(projectname)="data">
                        <div class="project-name" style="color: #40a6d5">
                        <!-- <router-link to="/video-request">{{
                            data.item.projectname
                        }}</router-link> -->
                        <router-link :to="`request-detail/${data.item.id}/?name=Dashboard&projectName=${data.item.projectname}`">{{ data.item.projectname}}</router-link>
                        </div>
                    </template>
                    <template #cell(Type)="data">
                        <div>{{ data.item.Type }}</div>
                    </template>
                    <template #cell(Credits)="data">
                        <div style="text-align center">{{ data.item.Credits }}</div>
                    </template>
                    <template #cell(videotype)="data">
                        <div>{{ data.item.videotype }}</div>
                    </template>
                    <template #cell(Submitted)="data">
                        <div>{{ data.item.Submitted }}</div>
                    </template>
                    <template #cell(shootdate)="data">
                        <div>{{ data.item.shootdate }}</div>
                    </template>
                    <template #cell(v1Target)="data">
                        <div>{{ data.item.v1Target }}</div>
                    </template>
                    <template #cell(addons)="data">
                        <div>{{ data.item.addons }}</div>
                    </template>
                    </b-table>
                    <div>
                        <b-pagination
                        class="pagination"
                        v-model="currentPageCancelled"
                        :total-rows="itemsCancelled.length"
                        :per-page="perPageCancelled"
                        aria-controls="my-table"
                        ></b-pagination>
                    </div>
                </div>
                <div v-else class="newrequest-table-data new-request-detaildata" style="text-align: center;">
                    No Records Found
                </div>
            </div>
            </div>
        </div>
        <!-- Completed table css End -->
        </div>
    </div>
</template>

<script>
// import {
//   BFormInput,
//   BFormGroup,
//   BForm,
//   BFormSelect,
//   BTable,
//   BPagination,
// } from "bootstrap-vue-next";
import firebase from "@/utils/firebaseInit"
import Firebase from "firebase";
import { dbCollections } from "@/utils/firebaseCollections"
// import firebase from '../../firebaseInit';
const db = firebase.firestore();
// import dbCollections  from "../../firebaseCollections.js";
import { mapActions,mapGetters } from 'vuex';
import moment from 'moment';
import Vue from 'vue'
// import { dbCollections } from '@/utils/firebaseCollections';
export default {
    name: 'DashboardComponet',
    components: {
        // BFormInput,
        // BFormGroup,
        // BForm,
        // BFormSelect,
        // BTable,
        // BPagination,
    },
    data() {
        return {
        newrequest: true,
        production: true,
        postproduction: true,
        canceled: true,
        scriptwriting: true,
        completed: true,
        form: {
            serachRequest: "",
            projectName: "",
            client: null,
            status: null,
            type: null,
        },
        statusOptions: [
            { value: null, text: "Status" },
            {value: 6, text: "Draft"},
            { value: 1, text: "New Request" },
            { value: 2, text: "Production" },
            { value: 3, text: "Post-Production" },
            { value: 4, text: "Competed" },
            { value: 5, text: "Cancel" },
        ],
        typeOptions: [
            { value: null, text: 'All' },
            { value: 'Shoot', text: 'Shoot Only' },
            { value: 'Edit', text: 'Edit Only' },
            { value: 'Shoot + Edit', text: 'Shoot + Edit' },
            { value: 'Other', text: 'Other' },
        ],
        // New Request Table Start
        fieldsNewRequest: [
            { key: "projectname", label: "Project Name" },
            { key: "videotype", label: "Type" },
            "Credits",
            { key: "projectType", label: "Video Type" },
            "Submitted",
            { key: "shootdate", label: "Shoot Date" },
            { key: "v1Target", label: "V1 Target" },
            { key: "addons", label: "Add-ons" },
        ],
        fieldsDraft: [
            { key: "projectname", label: "Project Name" },
            { key: "videotype", label: "Type" },
            "Credits",
            { key: "projectType", label: "Video Type" },
            "Submitted",
            { key: "shootdate", label: "Shoot Date" },
            { key: "v1Target", label: "V1 Target" },
            { key: "addons", label: "Add-ons" },
            { key: "action", label: "" },
            { key: "delete", label: "" },
        ],
        itemsNewReq: [],
        itemsDraf:[],
        // New Request Table End
        // Production Table Start
        fieldsProduction: [
            { key: "projectname", label: "Project Name" },
            { key: "videotype", label: "Type" },
            "Credits",
            { key: "projectType", label: "Video Type" },
            "Submitted",
            { key: "shootdate", label: "Shoot Date" },
            { key: "v1Target", label: "V1 Target" },
            { key: "addons", label: "Add-ons" },
        ],
        itemsProduct: [],
        // Production Table End
        // Post Production Table Start
        fieldsPostProduction: [
            { key: "projectname", label: "Project Name" },
            { key: "videotype", label: "Type" },
            "Credits",
            { key: "projectType", label: "Video Type" },
            "Submitted",
            { key: "shootdate", label: "Shoot Date" },
            { key: "v1Target", label: "V1 Target" },
            { key: "addons", label: "Add-ons" },
        ],
        itemsPostProduct: [],
        fieldsCancel: [
            { key: "projectname", label: "Project Name" },
            { key: "videotype", label: "Type" },
            "Credits",
            { key: "projectType", label: "Video Type" },
            "Submitted",
            { key: "shootdate", label: "Shoot Date" },
            { key: "v1Target", label: "V1 Target" },
            { key: "addons", label: "Add-ons" },
        ],
        itemsCancel: [],
        // Post Production Table End
        //  Completed Table start
        fieldsCompleted: [
            { key: "projectname", label: "Project Name" },
            { key: "videotype", label: "Type" },
            "Credits",
            { key: "projectType", label: "Video Type" },
            "Submitted",
            { key: "shootdate", label: "Shoot Date" },
            { key: "completed", label: "Completed" },
            { key: "addons", label: "Add-ons" },
        ],
        itemsComplete: [],
        // Completed Table End
        hideDeatilCollapse: false,
        perPageNewRequest: 10,
        currentPageNewRequest: 1,
        currentPageProduction: 1,
        perPageProduction:10,
        currentPagePostProduction: 1,
        perPagePostProduction:10,
        currentPageCompleted: 1,
        perPageCompleted:10,
        perPageCancelled:10,
        currentPageCancelled:1,
        currentPageDraft:1,
        perPageDraft:10,
        draft:true,
        };
    },
    methods: {
        // getDatassss(){
        //     const url = new URL(
        //     "https://app.subscriptionplus.net/api/v1/customer/6841146245407"
        // );

        // const params = {
        //     "include": "orders_count,total_spent,subscriptions.line_items",
        // };
        // Object.keys(params)
        //     .forEach(key => url.searchParams.append(key, params[key]));

        // const headers = {
        //     "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiODQ3MGFiNTRmMmM4MWZkYjdmMTA2Y2VmNThlOTk2OGVkMDlkZmVlN2ExZDBkNjU2NjA3NjlmM2M4NDJhYmI5ZGQyNzg1MDQzZGFjZTJmMWMiLCJpYXQiOjE2Nzk2NTMyNTAuNDEyMzA3LCJuYmYiOjE2Nzk2NTMyNTAuNDEyMzEsImV4cCI6MzU3MzEwOTI1MC40MTA3NzMsInN1YiI6IjEzNTciLCJzY29wZXMiOltdfQ.nXVEPErUZj2LDmjprj1K27yG6JrKgPkMN0cDl-PEx039mWE30EwoWk0Q1U0CzZY4ThMTMl8BctmSDsP7cJgnvA",
        //     "Content-Type": "application/json",
        //     "Accept": "application/json",
        // };

        // fetch(url, {
        //     method: "GET",
        //     headers,
        // }).then(response =>
        //     setTimeout(() => {
        //         console.log(response.json(),"DDddddd")
        //     }, 1000)
        //     )
        // },
        ...mapActions({
            // getUsers : 'userCollection/getUserCollection',
            getForm: 'formCollection/getformCollection'
        }),
        // ...mapMutations({
        //     updateUserData: 'user/updateUser'
        // }),
        getName(obj,name){
            let type = obj.formType
            return obj[`${type}${name}`]
        },
        getType(obj,name){
            if (name == 'type') {
                if (obj.formType == 'shootAndEdit') {
                    return 'Shoot + Edit'
                }
                if (obj.formType == 'onlyShoot') {
                return 'Shoot'
                }
                if (obj.formType == 'onlyEdit') {
                return 'Edit'
                }
                if (obj.formType == 'other') {
                return 'Other'
                }
            }
            else if (name == 'credits') {
                if (obj.formType == 'shootAndEdit') {
                return 2
                }
                if (obj.formType == 'onlyShoot') {
                return 1
                }
                if (obj.formType == 'onlyEdit') {
                return 1
                }
                if (obj.formType == 'other') {
                return 1
                }
            } else {
                return '';
            }
        },
        getAddon(obj){
            let array = []
            let type = obj.formType
            obj[`${type}${'CartArray'}`].forEach((data)=>{
                array.push(data.title)
            })
            return array.join(',');
        },
        getData(){
            if (this.getFormList.length) {
                let count = 0;
                let countFunction = (row) => {
                    if(count >= this.getFormList.length){
                        this.getDrafyData();
                        return;
                    } else {
                        let obj = {
                            userId:  row.userId,
                            projectname: this.getName(row,'NameOfProject'),
                            videotype: this.getType(row,'type'),
                            Credits: this.getType(row,'credits'),
                            Submitted: row.createdAt.seconds ? moment(new Date(row.createdAt.seconds * 1000)).format("MM/DD/YYYY") : moment(new Date(row.createdAt)).format("MM/DD/YYYY") ,
                            shootdate: this.getName(row,'ShootDate') ?  moment(new Date(this.getName(row,'ShootDate').seconds * 1000)).format("MM/DD/YYYY") : "N/A",
                            v1Target:  this.getName(row,'V1Target') ?  moment(new Date(this.getName(row,'V1Target') .seconds * 1000)).format("MM/DD/YYYY") : "N/A",
                            addons: this.getAddon(row) !== '' ? this.getAddon(row) : 'N/A',
                            projectType: this.getName(row,"TypeOfProject"),
                            id: row.id,
                            formType: row.formType
                        }
                        if (row.status == 1) {
                            this.itemsNewReq.push(obj);
                            count++;
                            countFunction(this.getFormList[count]);
                        } 
                        else if (row.status == 2) {
                            this.itemsProduct.push(obj);
                            count++;
                            countFunction(this.getFormList[count]);
                        } else if (row.status == 3) {
                            this.itemsPostProduct.push(obj);
                            count++;
                            countFunction(this.getFormList[count]);
                        }
                        else if (row.status == 5) {
                            this.itemsCancel.push(obj);
                            count++;
                            countFunction(this.getFormList[count]);
                        }
                        else if (row.status == 4) {
                            obj.completed = row.compleDate ?  moment(new Date(row.compleDate.seconds * 1000)).format("MM/DD/YYYY") : "N/A",
                            this.itemsComplete.push(obj);
                            count++;
                            countFunction(this.getFormList[count]);
                        }
                        else {
                            count++;
                            countFunction(this.getFormList[count]);
                        }
                    }
                }
                countFunction(this.getFormList[count]);
            } else {
                this.getDrafyData();
                // this.spinnerOf();
            }
        },
        newrequestToggle() {
            this.newrequest = !this.newrequest;
        },
        draftToggle(){
            this.draft = !this.draft;
        },
        productionToggle() {
            this.production = !this.production;
        },
        postproductionToggle() {
            this.postproduction = !this.postproduction;
        },
        canceledToggle() {
            this.canceled = !this.canceled;
        },
        scriptwritingToggle() {
            this.scriptwriting = !this.scriptwriting;
        },
        completedToggle() {
            this.completed = !this.completed;
        },
        HideDeatil() {
            this.completed = false;
            this.postproduction = false;
            this.canceled = false;
            this.production = false;
            this.newrequest = false;
            this.draft = false;
        },
        ShowDeatil() {
            this.completed = true;
            this.postproduction = true;
            this.canceled = true;
            this.production = true;
            this.newrequest = true;
            this.draft = true;
        },
        redirectToForm(item) {
            let path = '';
            if(item.formType === 'onlyShoot') {
                path = '/shootOnly';
            } else if(item.formType === 'onlyEdit') {
                path = '/editOnly';
            } else if(item.formType === 'shootAndEdit') {
                path = '/shootAndEdit';
            } else {
                return;
            }

            this.$router.push({path: path, query: {draftId: item.id}});
        },
        deleteForm(data){
            try {
               this.$swal({
				title: 'Are you sure?',
				text: `Are you sure you want to delete.`,
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'No',
				confirmButtonText: 'Yes',
			}).then((result)=>{
				if (result.value) {
                    this.isSpineer = true;
					// this.saveClick();
                    db.collection(dbCollections.DRAFTFORM).doc(data.id).delete().then(()=>{
                        this.$toast.success("Delete successfully.");
                        let customerTag = this.userData.tags.split(", ");
                        let updateObj = {
                            updatedAt: new Date(),
                            noOfDraft: Firebase.firestore.FieldValue.increment(-1)
                        }
                        if (customerTag.includes('subAccount')) {
                            db.collection(dbCollections.SHOPIFYUSER).doc(this.userData.mainAccountId).get().then((resp)=>{
                                if (resp.exists) {
                                    db.collection(dbCollections.SHOPIFYUSER).doc(resp.id.toString()).update(updateObj).then(()=>{
                                    // this.userData.noOfDraft = this.userData.noOfDraft - 1
                                    // this.updateUserData(this.userData);
                                    }).catch((error)=>{
                                        console.error(error);
                                    })
                                }
                            }).catch((error)=>{
                                console.error(error);
                            })
                        } else {
                            db.collection(dbCollections.SHOPIFYUSER).doc(this.userData.id.toString()).update(updateObj).then(()=>{
                                // this.userData.noOfDraft = this.userData.noOfDraft - 1
                                // this.updateUserData(this.userData);
                            }).catch((error)=>{
                                console.error(error);
                            })
                        }
                        let ind = this.itemsDraf.findIndex((draf)=>{
                            return draf.id == data.id;
                        })
                        if (ind !== -1) {
                            this.itemsDraf.splice(ind,1);
                            this.isSpineer = false;
                        } else {
                            this.isSpineer = false;
                        }
                    }).catch((error)=>{
                        this.isSpineer = false;
                        console.error(error);
                    })
				}
            }).catch((error)=>{
                console.error(error);
            }); 
            } catch (error) {
                console.error(error);
            }
            // console.log(data,"data");
        },
        getDrafyData(){
            db.collection(dbCollections.DRAFTFORM).where("userId","==",Vue.prototype.$userId).orderBy("createdAt","desc").get().then((resp)=>{
                if (resp.empty) {
                    console.info('no draft form found');
                }
                resp.docs.forEach((data)=>{
                    let obj = {
                        userId:  data.data().userId,
                        projectname: this.getName(data.data(),'NameOfProject'),
                        videotype: this.getType(data.data(),'type'),
                        Credits: this.getType(data.data(),'credits'),
                        Submitted: moment(new Date(data.data().createdAt.seconds * 1000)).format("MM/DD/YYYY"),
                        shootdate: this.getName(data.data(),'ShootDate') ?  moment(new Date(this.getName(data.data(),'ShootDate').seconds * 1000)).format("MM/DD/YYYY") : "N/A",
                        v1Target:  this.getName(data.data(),'V1Target') ?  moment(new Date(this.getName(data.data(),'V1Target') .seconds * 1000)).format("MM/DD/YYYY") : "N/A",
                        addons: this.getAddon(data.data()) !== '' ? this.getAddon(data.data()) : 'N/A',
                        projectType: this.getName(data.data(),"TypeOfProject"),
                        id: data.data().id,
                        formType: data.data().formType
                    }
                    this.itemsDraf.push(obj);
                })
                this.$root.$emit("totalDraftFormNumber", resp.docs.length);
            }).catch((error)=>{
                console.error(error);
            })
        }
    },
    computed:{
        ...mapGetters({
            // getUsersList : 'userCollection/getUserData',
            getFormList : 'formCollection/getformData',
            userData: 'user/getUserData'
        }),
        pagedRowsNewRequest() {
            const start = (this.currentPageNewRequest - 1) * this.perPageNewRequest;
            const end = start + this.perPageNewRequest;
            return this.itemsNewRequest.slice(start, end);
        },
        itemsNewRequest(){
            let temp = this.itemsNewReq
            if (this.form.client == null && this.form.type == null && this.form.serachRequest == '') {
                return temp;
            }
            if (this.form.client !== null) {
                temp = temp.filter((data)=>{
                return data.userId == this.form.client
                })
            }
            if (this.form.type !== null) {
                temp = temp.filter((data)=>{
                return data.videotype == this.form.type
                })
            }
            if (this.form.serachRequest !== '') {
                temp = temp.filter((rawData)=>{
                    return (
                        rawData["projectname"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["Credits"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["addons"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["videotype"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0
                    );
                })
            }
            return temp;
        },
        totalCreditNewRequest(){
            let total = 0
            this.itemsNewRequest.forEach((data)=>{
                total += data.Credits;
            })
            return total;
        },
        pagedRowsDraft() {
            const start = (this.currentPageDraft - 1) * this.perPageDraft;
            const end = start + this.perPageDraft;
            return this.itemsDraft.slice(start, end);
        },
        itemsDraft(){
            let temp = this.itemsDraf
            if (this.form.client == null && this.form.type == null && this.form.serachRequest == '') {
                return temp;
            }
            if (this.form.client !== null) {
                temp = temp.filter((data)=>{
                return data.userId == this.form.client
                })
            }
            if (this.form.type !== null) {
                temp = temp.filter((data)=>{
                return data.videotype == this.form.type
                })
            }
            if (this.form.serachRequest !== '') {
                temp = temp.filter((rawData)=>{
                    return (
                        rawData["projectname"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["Credits"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["addons"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["videotype"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0
                    );
                })
            }
            return temp;
        },
        pagedRowsProduction() {
            const start = (this.currentPageProduction - 1) * this.perPageProduction;
            const end = start + this.perPageProduction;
            return this.itemsProduction.slice(start, end);
        },
        itemsProduction(){
            let temp = this.itemsProduct
            if (this.form.client == null && this.form.type == null && this.form.serachRequest == '') {
                return temp;
            }
            if (this.form.client !== null) {
                temp = temp.filter((data)=>{
                return data.userId == this.form.client
                })
            }
            if (this.form.type !== null) {
                temp = temp.filter((data)=>{
                return data.videotype == this.form.type
                })
            }
            if (this.form.serachRequest !== '') {
                temp = temp.filter((rawData)=>{
                    return (
                        rawData["projectname"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["Credits"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["addons"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["videotype"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0
                    );
                })
            }
            return temp;
        },
        totalCreditProduction(){
            let total = 0
            this.itemsProduction.forEach((data)=>{
                total += data.Credits;
            })
            return total;
        },
        pagedRowsPostProduction() {
            const start = (this.currentPagePostProduction - 1) * this.perPagePostProduction;
            const end = start + this.perPagePostProduction;
            return this.itemsPostProduction.slice(start, end);
        },
        itemsPostProduction(){
            let temp = this.itemsPostProduct
            if (this.form.client == null && this.form.type == null && this.form.serachRequest == '' && this.form.videoediter == null) {
                return temp;
            }
            if (this.form.client !== null) {
                temp = temp.filter((data)=>{
                return data.userId == this.form.client
                })
            }
            if (this.form.type !== null) {
                temp = temp.filter((data)=>{
                return data.videotype == this.form.type
                })
            }
            if (this.form.serachRequest !== '') {
                temp = temp.filter((rawData)=>{
                    return (
                        rawData["projectname"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["Credits"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["addons"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["videotype"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0
                    );
                })
            }
        return temp;
        },
        itemsCancelled(){
            let temp = this.itemsCancel
            if (this.form.client == null && this.form.type == null && this.form.serachRequest == '' && this.form.videoediter == null) {
                return temp;
            }
            if (this.form.client !== null) {
                temp = temp.filter((data)=>{
                return data.userId == this.form.client
                })
            }
            if (this.form.type !== null) {
                temp = temp.filter((data)=>{
                return data.videotype == this.form.type
                })
            }
            if (this.form.serachRequest !== '') {
                temp = temp.filter((rawData)=>{
                    return (
                        rawData["projectname"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["Credits"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["addons"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["videotype"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0
                    );
                })
            }
        return temp;
        },
        totalCreditCancelled(){
            let total = 0
            this.itemsCancelled.forEach((data)=>{
                total += data.Credits;
            })
            return total;
        },
        pagedRowsCompleted() {
            const start = (this.currentPageCompleted - 1) * this.perPageCompleted;
            const end = start + this.perPageCompleted;
            return this.itemsCompleted.slice(start, end);
        },
        totalCreditPostProduction(){
            let total = 0
            this.itemsPostProduction.forEach((data)=>{
                total += data.Credits;
            })
            return total;
        },
        pagedRowsCancelled() {
            const start = (this.currentPageCancelled - 1) * this.perPageCancelled;
            const end = start + this.perPageCancelled;
            return this.itemsCancelled.slice(start, end);
        },
        itemsCompleted(){
            let temp = this.itemsComplete
            if (this.form.client == null && this.form.type == null && this.form.serachRequest == '' && this.form.videoediter == null) {
                return temp;
            }
            if (this.form.client !== null) {
                temp = temp.filter((data)=>{
                return data.userId == this.form.client
                })
            }
            if (this.form.type !== null) {
                temp = temp.filter((data)=>{
                return data.videotype == this.form.type
                })
            }
            if (this.form.serachRequest !== '') {
                temp = temp.filter((rawData)=>{
                    return (
                        rawData["projectname"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["Credits"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["addons"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0 ||
                        rawData["videotype"]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.form.serachRequest.toLowerCase()) >= 0
                    );
                })
            }
            return temp;
        },
        totalCreditCompleted(){
            let total = 0
            this.itemsCompleted.forEach((data)=>{
                total += data.Credits;
            })
            return total;
        },
        // clientOptions(){
        // let name = [];
        // name.push({text: "All", value: null});
        // this.getUsersList.forEach((data)=>{
        //     if (data.first_name && data.last_name) {
        //         let obj = {text: `${data.first_name} ${data.last_name}`, value: data.id.toString()};
        //         name.push(obj);
        //     } else {
        //         let obj = {text: `(${data.email})`, value: data.id.toString()};
        //         name.push(obj);
        //     }
        // })
        // return name
        // },
    },
    created() {
        // this.spinnerOn();
        // let userFound = this.getUsersList.length != 0;
        // if(!userFound){
        //     this.getUsers().then(()=>{
        //     }).catch(error=>{
        //         console.error("error",error)
        //     });
        // }
        let formFound = this.getFormList.length != 0;
        if(!formFound){
            this.getForm().then(()=>{
                this.getData();
            }).catch(error=>{
                console.error("error",error);
            })
        } else {
            this.getData();
        }
    },
}
</script>

<style>
.complete-form {
    background: #69CC15;
    color: #fff;
    padding: 1.5px 0px 0.5px 6px;
    height: 24px;
    display: flex;
    justify-content: space-between;
    border-radius: 1px;
    align-items: center;
    cursor: pointer;
    font-family: 'Chivo-Medium';
    font-size: 11px;
}
</style>
import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollections"
const db = firebase.firestore();
import Vue from 'vue'

export default {
    namespaced: true,
    state: {
      formData:[]
    },
    getters: {
      getformData: state => state.formData
    },
    mutations: {
      setformDataArr(state,payload){
        state.formData = payload;
      },
      setUpdateFormObj(state, payload) {
        state.formData.push(payload);
        // let ind = state.formData.findIndex((data)=>{
        //     return data.id == payload.id
        // });
        // if(ind > -1) {
        //     state.formData[ind] = payload;
        // }
      }
    },
    actions: {
      getformCollection(state){
        return new Promise((resolve,reject)=>{
          try{
            let formData = [];
            db.collection(dbCollections.FORM).where("userId","==",Vue.prototype.$userId).orderBy("createdAt","desc").get().then(snapshot=>{
                formData = [];              
                if(snapshot.empty){
                    resolve("No Data Found.");
                    state.commit("setformDataArr",formData);
                  }
                  else
                  {
                      snapshot.forEach(doc=>{
                          if(doc.data())
                          formData.push(doc.data());
                      });
                      state.commit("setformDataArr",formData);
                      
                      if(formData.length == 0)
                      {
                          resolve("No Data Found.");
                      }
                      else
                      {
                          resolve("Records Found.");
                  }
                }
              }).catch((error)=>{
                console.error("error",error);
                reject(error);
              })
          }
          catch(error){
            console.error("error",error);
            reject(error);
          }
        });
      }
    },
}
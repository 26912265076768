<template>
  <div>
    <div class="cart_drawer_overlay" @click="Addon"></div>
    <div class="cart_drawer">
      <div class="cart_header d-flex justify-content-between">
        <h3>Cart</h3>
        <button class="drawer_close border-0 p-0" @click="Addon">
          <svg
            class="Icon Icon--close"
            role="presentation"
            viewBox="0 0 16 14"
            style="width: 15px; height: 15px"
          >
            <path
              d="M15 0L1 14m14 0L1 0"
              stroke="currentColor"
              fill="none"
              fill-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <div class="cart_body" v-if="checkout.length">
        <div
          class="cartitem d-flex"
          v-for="item in checkout"
          v-bind:key="item.id"
        >
          <div class="cart_image">
            <img :src="item.avatar" />
          </div>
          <div class="cartitem_info">
            <h5>{{ item.title }}</h5>
            <div class="d-flex justify-content-between">
              <!-- <div class="quantityselector">
                <button @click="item.quantity--">-</button>
                <input type="text" v-model="item.quantity" name="quantity" />
                <button @click="item.quantity++">+</button>
              </div> -->
              <span class="price">${{ item.price }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="cart_body">
        <p style="font-size: 26px;text-align: center;">No Add-on</p> 
        </div>
      <div class="cart_footer">
        <div class="subtotal d-flex justify-content-between">
          <span class="label_text">Total</span>
          <span class="amount">$ {{totalPrice}}</span>
        </div>
        <!-- <p>Final charges calculated at checkout.</p>
        <button
          type="button"
          class="cart_drawer_checkout Button border-0 text-white w-100 d-block text-center"         
        >
          Check Out
        </button> -->
        
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters,mapActions} from "vuex";
export default {
  name: "CartDrawer",
  data() {
    return {
      isActive: true,
      checkout: [], 
      totalPrice: 0,    
      cartitem: [
        {
          avatar: require("@/assets/cartImg/script-writer-small_dd5a62b0-bf6f-404f-ae43-b4bb9a1b0ed3.png"),
          title: "Script Writing",
          id:"Scriptwriting",
          type: "Standard Add-on"
        },
        {
          avatar: require("@/assets/cartImg/LLV_PRO_PLAN_576_cc7b7d7c-83cd-4d2a-9c74-14ee1271ace7.png"),
          title: "Custom Thumbnail Design",
          id: "CustomThumbnailDesign",
          type: "Standard Add-on"
        },
        {
          avatar: require("@/assets/cartImg/rush-edit_04618be7-6b4b-4aea-ae9a-1957740b659e.png"),
          title: "Rush Edit",
          id:"RushEdit",
          type: "Premium Add-on"
        },
        {
          avatar: require("@/assets/cartImg/LLV_PRO_PLAN_576_cc7b7d7c-83cd-4d2a-9c74-14ee1271ace7.png"),
          title: "Standard Version",
          id: "StandardVersion",
          type: "Standard Add-on"
        },
        {
          avatar: require("@/assets/cartImg/LLV_PRO_PLAN_576_cc7b7d7c-83cd-4d2a-9c74-14ee1271ace7.png"),
          title: "Vertical Story",
          id:"VerticalStory",
          type: "Standard Add-on"
        },
        {
          avatar: require("@/assets/cartImg/LLV_PRO_PLAN_576_cc7b7d7c-83cd-4d2a-9c74-14ee1271ace7.png"),
          title: "Square Version",
          id: "SquareVersion",
          type: "Standard Add-on"
        },
        {
          avatar: require("@/assets/cartImg/LLV_PRO_PLAN_576_cc7b7d7c-83cd-4d2a-9c74-14ee1271ace7.png"),
          title: "Vertical Version",
          id: "VerticalVersion",
          type: "Standard Add-on"
        },
        {
          avatar: require("@/assets/cartImg/LLV_PRO_PLAN_576_cc7b7d7c-83cd-4d2a-9c74-14ee1271ace7.png"),
          title: "Interview Development",
          id: "InterviewDevelopment",
          type: "Standard Add-on"
        },
        {
          avatar: require("@/assets/cartImg/LLV_PRO_PLAN_576_cc7b7d7c-83cd-4d2a-9c74-14ee1271ace7.png"),
          title: "Interview Questions",
          id: "InterviewQuestions",
          type: "Standard Add-on"
        },
        {
          avatar: require("@/assets/cartImg/Studio_Small_2c036249-3510-4e1f-b499-357cedd047a6.png"),
          title: "Studio Rental",
          id: "StudioRental",
          type: "Premium Add-on"
        },
        {
          avatar: require("@/assets/cartImg/Home_Rental.png"),
          title: "Home Rental",
          id: "HomeRental",
          type: "Premium Add-on"
        },
        {
          avatar: require("@/assets/cartImg/LimeLite_GO_baf266b3-d242-406e-96fa-4f492b7fe649.png"),
          title: "LimeLite Go",
          id: "LimeLiteGo",
          type: "Premium Add-on"
        },
        {
          avatar: require("@/assets/cartImg/voiceover-small_a6a5097f-2d3e-4a1a-9ef5-35d0eb153c73.png"),
          title: "Voicoever Artist",
          id: "VoicoeverArtist",
          type: "Standard Add-on"
        },
        {
          avatar: require("@/assets/cartImg/Professional-Talent-Small_0d6ca63e-779a-417f-8cc9-194109a4d803.png"),
          title: "Professional Actor",
          id: "ProfessionalActor",
          type: "Premium Add-on"
        },
        {
          avatar: require("@/assets/cartImg/second-camera-clean_81e4ce6d-488c-4ad1-980e-b08ee3de1e8d.png"),
          title: "Second Camera",
          id: "SecondCamera",
          type: "Standard Add-on"
        },
        {
          avatar: require("@/assets/cartImg/Drone_Shoot_Small_9bb1ee52-871d-4e3b-855f-924aebbf83f3.png"),
          title: "Drone Videography",
          id: "DroneVideography",
          type: "Premium Add-on"
        },
        {
          avatar: require("@/assets/cartImg/Photography_dcbb1129-8f98-4062-bb6d-8e81edd85287.png"),
          title: "Candid Photography",
          id: "CandidPhotography",
          type: "Premium Add-on"
        },
        {
          avatar: require("@/assets/cartImg/make-up-artist-Small.png"),
          title: "Make-up Artist",
          id: "Make-upArtist",
          type: "Premium Add-on"
        },
        {
          avatar: require("@/assets/cartImg/logo-animation-clean.png"),
          title: "Logo Animation",
          id: "LogoAnimation",
          type: "Standard Add-on"
        },
        {
          avatar: require("@/assets/cartImg/LLV_PRO_PLAN_576_cc7b7d7c-83cd-4d2a-9c74-14ee1271ace7.png"),
          title: "Weekend Shoot(Prefered)",
          id: "WeekendShootPrefered",
          type: "Standard Add-on"
        },
        {
          avatar: require("@/assets/cartImg/LLV_PRO_PLAN_576_cc7b7d7c-83cd-4d2a-9c74-14ee1271ace7.png"),
          title: "Weekend Shoot(Alternate)",
          id: "WeekendShootAlternate",
          type: "Standard Add-on"
        },
        {
          avatar: require("@/assets/cartImg/Nights_Weekends.png"),
          title: "After Hours Shoot(Preferred)",
          id: "AfterHoursShootPreferred",
          type: "Standard Add-on"
        },
        {
          avatar: require("@/assets/cartImg/Nights_Weekends.png"),
          title: "After Hours Shoot(Alternate)",
          id: "AfterHoursShootAlternate",
          type: "Standard Add-on"
        },
      ],
      addonItem: [],
    };
  },
  computed: {
    ...mapGetters({
      set: 'setting/getSetting'
    }),
    settingArr(){
      let ind = this.set.findIndex((ele)=>{
        return ele.id == 'addOnPricelist'
      })
      return this.set[ind]
    }
  },
  methods: {
    ...mapActions({
      getSett: 'setting/getSettingsData'
    }),
    Addon: function () {
      this.isActive = !this.isActive;
      if (this.isActive == true) {
        document.body.className = "drawer-open";
      } else {
        document.body.className = "";
      }
      this.isActive = !this.isActive;
    },
  },
  created() {
    let settinhFound = this.set.length != 0;
    if(!settinhFound){
        this.getSett().then(()=>{
        }).catch(error=>{
            console.error("error",error)
        });
    }
    this.$root.$on("cartAdd", (name,field,price)=> {
      if (name == "WeekendShootPrefered" || name == "WeekendShootAlternate") {
        if (field == "add") {
          let cartInd = this.cartitem.findIndex((data)=>{
            return data.id == name
          })
          if (cartInd > -1) {
            let obj = this.cartitem[cartInd]
            if (price) {
              obj.price = parseInt(price)
              this.totalPrice += parseInt(price);
            } else {
              obj.price = 0;
              this.totalPrice += 0;
            }
            this.checkout.push(obj);
          }
          // this.checkout.push(name);
        }
        if (field == "remove") {
          let ind = this.checkout.findIndex((data)=>{
            return data.id == name
          });
          if (ind > -1) {
            this.totalPrice -= this.checkout[ind].price;
            this.checkout.splice(ind,1);
          }
        }
        return;
      }
      if (name == "LimeLiteGo") {
        if (field == "add") {
          let cartInd = this.cartitem.findIndex((data)=>{
            return data.id == name
          })
          if (cartInd > -1) {
            let obj = this.cartitem[cartInd]
            let nm = "";
            Object.keys(this.settingArr).forEach((data)=>{
              if (data == price) {
                nm = data
              }
            })
            if (nm !== undefined) {
              obj.price = this.settingArr[nm];
              this.totalPrice += this.settingArr[nm];
            } else {
              obj.price = 0
            }
            // let priceInd = this.set
            // obj.price = "$ 567"
            this.checkout.push(obj);
          }
          return;
        // this.checkout.push(name);
        }
        if (field == "remove") {
          let ind = this.checkout.findIndex((data)=>{
            return data.id == name
          });
          if (ind > -1) {
            this.totalPrice -= this.checkout[ind].price;
            this.checkout.splice(ind,1);
          }
          return;
        }
      }
      if (name == "RushEdit") {
        if (field == "add") {
          let cartInd = this.cartitem.findIndex((data)=>{
            return data.id == name
          })
          if (cartInd > -1) {
            let obj = this.cartitem[cartInd]
            let nm = "";
            Object.keys(this.settingArr).forEach((data)=>{
              if (data == price) {
                nm = data
              }
            })
            if (nm !== undefined) {
              obj.price = this.settingArr[nm];
              this.totalPrice += this.settingArr[nm];
            } else {
              obj.price = 0
            }
            // let priceInd = this.set
            // obj.price = "$ 567"
            this.checkout.push(obj);
          }
          return;
        // this.checkout.push(name);
        }
        if (field == "remove") {
          let ind = this.checkout.findIndex((data)=>{
            return data.id == name
          });
          if (ind > -1) {
            this.totalPrice -= this.checkout[ind].price;
            this.checkout.splice(ind,1);
          }
          return;
        }
      }
      if (field == "add") {
        let cartInd = this.cartitem.findIndex((data)=>{
          return data.id == name
        })
        if (cartInd > -1) {
          let obj = this.cartitem[cartInd]
          let nm = "";
          if (name == "AfterHoursShootPreferred" || name == "AfterHoursShootAlternate") {
            Object.keys(this.settingArr).forEach((data)=>{
              if (data == "AfterHoursShoot") {
                nm = data
              }
            })
          } else {
            Object.keys(this.settingArr).forEach((data)=>{
              if (data == name) {
                nm = data
              }
            })
          }
          if (nm !== undefined) {
            obj.price = this.settingArr[nm];
            this.totalPrice += this.settingArr[nm];
          } else {
            obj.price = 0
          }
          // let priceInd = this.set
          // obj.price = "$ 567"
          this.checkout.push(obj);
        }
        // this.checkout.push(name);
      }
      if (field == "remove") {
        let ind = this.checkout.findIndex((data)=>{
          return data.id == name
        });
        if (ind > -1) {
          this.totalPrice -= this.checkout[ind].price;
          this.checkout.splice(ind,1);
        }
      }
    })
    this.$root.$on('getAddOn', ()=>{
      let arr = [];
      this.checkout.forEach((data)=>{
        let obj = {
          type: data.type,
          id: data.id,
          title: data.title,
          price: data.price,
        }
        arr.push(obj);
      })
      this.$root.$emit('addOnArray',arr,this.totalPrice);
    });
    this.$root.$on('addOnGet', (arr)=>{
      arr.forEach((ele)=>{
        let ind = this.cartitem.findIndex((data)=>{
          return data.id == ele.id
        });
        if (ind > -1) {
          ele.avatar = this.cartitem[ind].avatar
          this.checkout.push(ele);
          this.totalPrice += ele.price;
        }
      });
    });
  },
  beforeDestroy() {
    this.$off('cartAdd');
    this.$off('getAddOn');
  },
};
</script>

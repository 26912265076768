import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollections"
const db = firebase.firestore();
export default{
    namespaced: true,
    state: {
        settingCollection:[],
        userData: {},
      },
    getters: {
        getSetting: state => state.settingCollection,
        getUserData: state => state.userData
    },
    mutations: {
        setSettingArray(state, payload){
            state.settingCollection = payload;
        },
        setUserData(state, payload){
            state.userData = payload;
        }
    },
    actions: {
        getSettingsData(state){
            return new Promise((resolve, reject) => {
            try {
                db.collection(dbCollections.SETTING).get().then((resp)=>{
                if (resp.empty) {
                    resolve([]);
                    return;
                }
                let arr = [];
                resp.forEach((data)=>{
                    arr.push(data.data());
                });
                state.commit("setSettingArray",arr);
                resolve(arr);
                })
            } catch (error) {
                console.error(error)
                reject(error);
            }
            });
        },
        // getUserData(){
        //     return new Promise((resolve, reject) => {
                
        //     })
        // }
    },
}
import Vue from 'vue'
import Vuex from 'vuex'
import setting from './setting'
import user from './user'
import formCollection from './form/index'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters:{
    getRoutes: state=> state
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    setting,
    formCollection,
    user
  }
})

import firebaseConfig from './firebaseConfig'
import firebase from "firebase"
import "firebase/firestore"
import "firebase/storage"
import "firebase/auth"
import "firebase/analytics";
import "firebase/app";
const firebaseApp = firebase.initializeApp(firebaseConfig)

firebase.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
};

// for using cache with unlimited size.
firebase.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

// firebase.firestore().enablePersistence()
// .catch(() => {
//     // if (err.code == 'failed-precondition') {
//     // } else if (err.code == 'unimplemented') {
//     // }else{
//     // }
// });

export default firebaseApp
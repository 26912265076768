<template>
  <header>
    <div class="container">
      <div class="header_logo">
        <a @click="redirect"
         style="cursor:pointer"
          ><img
            alt="logo"
            src="../../assets/Images/limelite-logo-color.png"
            class="img-fluid"
            style="width: 270px;"
        /></a>
      </div>
      <div class="header_right" v-if="$route.name !== 'SubAccountRegister'">
        <div class="chat_now_content">
          <!-- <p>
            Need help? Chat Now:
            <a href="#"
              ><img
                alt="logo"
                src="../../assets/Images/chat.png"
                class="img-fluid"
                style="margin-left: 1px;"
            /></a>
          </p> -->

          <div class="step_progress" v-show="$route.name != 'home'  && $route.name !== 'Form' && $route.name !== 'FormDetail'" v-if="$route.name !== 'SubAccountRegister'">
            <label for="file">{{progressValue}}%</label>
            <progress id="file" :value="progressValue" max="100">{{progressValue}}%</progress>
          </div>
        </div>
      </div>
      <div v-if="$route.name !== 'home' && $route.name !== 'Form' &&  $route.name !== 'FormDetail' && $route.name !== 'SubAccountRegister'" style="padding-left:10px;" >
        <img src="@/assets/cartImg/icons8-shopping-cart-50.png" height="40px" width="40px" @click="addOnClick" style="cursor:pointer;"/>
        <!-- <button @click="addOnClick">Add-on</button> -->
      </div>
      <div class="header_right" v-if="$route.name == 'home' && $route.name !== 'SubAccountRegister' " style="margin-left: 31%;">
            <div class="request-a-video" @click="redirectRequest()">Request a Video</div>
      </div>
    </div>
  </header>
</template>

<script>
import {dbCollections} from '@/utils/firebaseCollections';
import firebase from '@/utils/firebaseInit';
const db = firebase.firestore()
import CartDrawer from "@/components/FormFour/CartDrawer.vue";
import { mapGetters,mapActions } from "vuex";
export default {
   /* eslint-disable */ 
  name: "newHeader",
  comments:{
    CartDrawer
  },
  data() {
    return {
      progressValue: 0,
      isAddOn: false,
      // totalDraftForm: 0,
    }
  },
  computed:{
    ...mapGetters({
      settings: "setting/getSetting",
      userData: 'user/getUserData'
    }),
    validDraftLength(){
      if(this.settings && this.settings.length) {
          let index = this.settings.findIndex((item) => item.id === "numberOfDraftForm");
          if(index > -1) {
            return this.settings[index].maxnumberOfDraftForm;
          } else {
            return 5;
          }
      } else {
        return 5;
      }
    },
  },
  methods: {
    ...mapActions({
      getSett: 'setting/getSettingsData',
      getUser: 'user/getUserCollection',
    }),
    redirect(){
      if (this.$route.name === "home" || this.$route.name === "SubAccountRegister") {
        window.parent.postMessage(true, 'https://limelitevideos-testing.com');
      } else {
        this.$router.push({name: "home"});
      }
    },
    addOnClick(){
      this.isAddOn = true;
      if (this.isAddOn) {
        document.body.className = "drawer-open";
      }
    },
    redirectRequest(){
      let customerTag = this.userData.tags.split(", ");
       if (customerTag.includes("subAccount")) {
          db.collection(dbCollections.SHOPIFYUSER).doc(this.userData.mainAccountId).get().then((resp)=>{
            if (!resp.empty) {
              if(resp.data().noOfDraft < this.validDraftLength) {
                this.$router.push({name: 'Form'});
              } else {
                this.$toast.warning("You have reached the limit of draft forms, please complete your draft forms .")
              }
            } else {
              console.error("Main Account Data Not found")
            }
          }).catch((error)=>{
            console.error(error);
            return 0
          })
      } else {
        if(this.userData.noOfDraft < this.validDraftLength) {
            this.$router.push({name: 'Form'});
          } else {
            this.$toast.warning("You have reached the limit of draft forms, please complete your draft forms .")
        }
      }
    }
  },
  created() {
    let settinhFound = this.settings.length != 0;
    if(!settinhFound){
        this.getSett().then(()=>{
        }).catch(error=>{
            console.error("error",error)
        });
    }
    let userFound = Object.keys(this.userData).length != 0;
    if(!userFound){
        this.getUser().then(()=>{
        }).catch(error=>{
            console.error("error",error)
        });
    }
    this.$root.$on("editProgressBar",(val)=>{
      this.progressValue = val;
    })
    // this.$root.$on("totalDraftFormNumber",(val)=>{
    //   this.totalDraftForm = val;
    // });

  },
  beforeDestroy() {
    this.$root.$off("editProgressBar");
    // this.$root.$off("totalDraftFormNumber");
  },
};
</script>



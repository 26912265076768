<template>
<footer>
     <div class="footer-section">
        <div class="container">
            <div class="footer_top_text">
            <p>Have questions or need help? <br/> <font>Chat live</font> with a LimeLite producer.  Or call <a href="tel:8883885463">(888) 388-5463</a>.</p>
            </div>
            <div class="row"> 
                 <div class="col-5 col-md-6">
   <p>Copyright © 2023 LimeLite Videos</p>
    </div>
                <div class="col-7 col-md-6">
                    <ul>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Terms of Use</a></li>
                        <li><a href="#">Sales and Refunds</a></li>
                    </ul>
                    </div>
 
</div>
    </div>
    </div>

    <!-- <div class="footer-section">
        <div class="container">
            <div class="row footer-content">
                <div class="col-md-4">
                    <div class="footer-logo">
                        <a href="/"><img alt="logo" src="../../assets/Images/Header-Logo.png" class="img-fluid"></a>
                        <div class="social-media">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/limelitevideos" taget="_blank">
                                        <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-facebook" viewBox="0 0 32 32">
                                            <path fill="#444" d="M18.56 31.36V17.28h4.48l.64-5.12h-5.12v-3.2c0-1.28.64-2.56 2.56-2.56h2.56V1.28H19.2c-3.84 0-7.04 2.56-7.04 7.04v3.84H7.68v5.12h4.48v14.08h6.4z"></path>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/limelitevideos/" taget="_blank">
                                        <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-instagram" viewBox="0 0 32 32">
                                            <path fill="#444" d="M16 3.094c4.206 0 4.7.019 6.363.094 1.538.069 2.369.325 2.925.544.738.287 1.262.625 1.813 1.175s.894 1.075 1.175 1.813c.212.556.475 1.387.544 2.925.075 1.662.094 2.156.094 6.363s-.019 4.7-.094 6.363c-.069 1.538-.325 2.369-.544 2.925-.288.738-.625 1.262-1.175 1.813s-1.075.894-1.813 1.175c-.556.212-1.387.475-2.925.544-1.663.075-2.156.094-6.363.094s-4.7-.019-6.363-.094c-1.537-.069-2.369-.325-2.925-.544-.737-.288-1.263-.625-1.813-1.175s-.894-1.075-1.175-1.813c-.212-.556-.475-1.387-.544-2.925-.075-1.663-.094-2.156-.094-6.363s.019-4.7.094-6.363c.069-1.537.325-2.369.544-2.925.287-.737.625-1.263 1.175-1.813s1.075-.894 1.813-1.175c.556-.212 1.388-.475 2.925-.544 1.662-.081 2.156-.094 6.363-.094zm0-2.838c-4.275 0-4.813.019-6.494.094-1.675.075-2.819.344-3.819.731-1.037.4-1.913.944-2.788 1.819S1.486 4.656 1.08 5.688c-.387 1-.656 2.144-.731 3.825-.075 1.675-.094 2.213-.094 6.488s.019 4.813.094 6.494c.075 1.675.344 2.819.731 3.825.4 1.038.944 1.913 1.819 2.788s1.756 1.413 2.788 1.819c1 .387 2.144.656 3.825.731s2.213.094 6.494.094 4.813-.019 6.494-.094c1.675-.075 2.819-.344 3.825-.731 1.038-.4 1.913-.944 2.788-1.819s1.413-1.756 1.819-2.788c.387-1 .656-2.144.731-3.825s.094-2.212.094-6.494-.019-4.813-.094-6.494c-.075-1.675-.344-2.819-.731-3.825-.4-1.038-.944-1.913-1.819-2.788s-1.756-1.413-2.788-1.819c-1-.387-2.144-.656-3.825-.731C20.812.275 20.275.256 16 .256z"></path>
                                            <path fill="#444" d="M16 7.912a8.088 8.088 0 0 0 0 16.175c4.463 0 8.087-3.625 8.087-8.088s-3.625-8.088-8.088-8.088zm0 13.338a5.25 5.25 0 1 1 0-10.5 5.25 5.25 0 1 1 0 10.5zM26.294 7.594a1.887 1.887 0 1 1-3.774.002 1.887 1.887 0 0 1 3.774-.003z"></path>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/64854099" taget="_blank">
                                        <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-linkedin" viewBox="0 0 24 24">
                                            <path d="M4.98 3.5C4.98 4.881 3.87 6 2.5 6S.02 4.881.02 3.5C.02 2.12 1.13 1 2.5 1s2.48 1.12 2.48 2.5zM5 8H0v16h5V8zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V24H24V13.869c0-7.88-8.922-7.593-11.018-3.714V8z"></path>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCvoYkGgjQOwzqloxcCFI_gQ" taget="_blank">
                                        <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-youtube" viewBox="0 0 21 20">
                                            <path fill="#444" d="M-.196 15.803q0 1.23.812 2.092t1.977.861h14.946q1.165 0 1.977-.861t.812-2.092V3.909q0-1.23-.82-2.116T17.539.907H2.593q-1.148 0-1.969.886t-.82 2.116v11.894zm7.465-2.149V6.058q0-.115.066-.18.049-.016.082-.016l.082.016 7.153 3.806q.066.066.066.164 0 .066-.066.131l-7.153 3.806q-.033.033-.066.033-.066 0-.098-.033-.066-.066-.066-.131z"></path>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <a href="https://www.blacktentproductions.com/" target="_blank" class="js-no-transition">Powered by Black Tent, LLC </a>
                    </div>
                </div>
                <div class="col-md-4">
                    <p class="footer_learnmore">Learn More</p>
                    <ul class="footer_nav">
                        <li><a href="">Home</a></li>
                        <li><a href="">Pricing</a></li>
                        <li><a href="">Add-ons</a></li>
                        <li><a href="">How it Works</a></li>
                        <li><a href="">Samples</a></li>
                        <li><a href="">Why Us</a></li>

                    </ul>
                </div>
                <div class="col-md-4">
                    <p class="footer_learnmore">BECOME A VIDEO MARKETING EXPERT</p>
                    <p>Receive video marketing strategies and tips that will help grow your business. You can unsubscribe at any time. </p>
                    <form class="footer-form">
                        <div class="form-group">
                            <input type="text" id="email" v-validate="'required|email'" name="email" class="form-control" placeholder="Enter Your Email" v-model="formData.email">
                            <span class="error_message">{{ errors.first('email') }}.</span>
                        </div>
                        <button type="button" @click="submitData"><svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-email" viewBox="0 0 64 64">
                                <path d="M63 52H1V12h62zM1 12l25.68 24h9.72L63 12M21.82 31.68L1.56 51.16m60.78.78L41.27 31.68" /></svg></button>
                    </form>

                </div>
            </div>
        </div>

    </div> -->
</footer>
</template>

<script>
export default {
    name: "newFooter",
    data() {
        return {
            formData: {
                email: ''
            }
        }
    },
    methods: {
        submitData() {
          
        }
    }
}
</script>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import axios from 'axios'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
import './assets/css/style.css'
import VeeValidate from 'vee-validate';
// Vue.use(VeeValidate);
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
Vue.use(Toast);
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// Vue.prototype.$axios = axios;
import VCalendar from 'v-calendar';
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

import { setupCalendar} from 'v-calendar'

setupCalendar({
  componentPrefix: 'vc',
});

Vue.config.productionTip = false
Vue.prototype.$userId = '';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
